@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@border-radius: 7px;
@box-shadow: 0 0 29px rgba(12, 15, 50, 0.09);
@color1: #465fc3;

@sidebargrey: #a5aabf;

[data-amplify-authenticator] {
  // width: 100%;
  min-height: calc(100vh - 64px - 92px);
  // display: flex;
  // justify-content: center;
  // align-items: center;

  & [data-amplify-router] {
    border-style: none;
    border-radius: @border-radius;
  }
}

[data-amplify-theme] {
  --amplify-components-authenticator-router-border-style: none;
  --amplify-components-authenticator-router-box-shadow: @box-shadow;
}

body {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.035em;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-layout-sider {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh !important;
  width: 227px !important;
  max-width: 227px !important;
  min-width: 227px !important;
  position: fixed;
  left: 0;
  box-shadow: @box-shadow;
  z-index: 9999;

  font-weight: 600;

  .logowrapper {
    width: 100%;

    .ant-divider {
      border-color: @color1;
      border-radius: 4px;
      border-width: 2px;
      width: 124px;
      min-width: 124px;
      margin: 0 auto;
    }
  }

  #sidebarOptions {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(~"100vh - 300px");
  }

  .ant-menu {
    background: transparent;
  }

  #logout {
    position: absolute;
    bottom: 46px;
    background-color: transparent;
    box-shadow: none;
    color: #a5aabf;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

img#logo {
  height: 79px;
  width: 52px;
  margin: 30px auto 10px;
  display: block;
}

img#customerlogo {
  margin: 10px auto 40px;
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 40px;
  width: auto;
  height: auto;
  padding: 0 23%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-card-head {
  font-size: 20px;
}

.ant-comment-content {
  font-size: 18px;
}

.center-div-by-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 75vh;
}

.center-div-by-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30vw;
}

.full-width-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 10rem;
  max-height: 30rem;
  color: #152939;
  background-color: white;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  line-height: 1.1;
  padding: 25px;
}

.customer-selector {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  color: #152939;
  background-color: white;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  line-height: 1.1;
  padding: 25px;
  align-items: center;
  text-align: center;
}

.customer-buttons {
  display: flex;
  flex-direction: column;
}

.customer-buttons .ant-btn {
  width: 180px;
  font-weight: bold;
}

.recharts-wrapper svg {
  overflow: inherit !important;
}

.something-wrong {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  color: #152939;
  background-color: white;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  line-height: 1.6;
  padding: 50px;
  align-items: center;
  text-align: center;
}

.avatar-uploader > .ant-upload {
  width: 88px !important;
  height: 48px !important;
}

.selectable {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.admin-text-box {
  background-color: white;
  padding: 8px;
}

.admin-editor-col {
  overflow: scroll;
  max-height: 800px;
}

@primary-color: #465FC3;@body-background: #F4F4F7;@component-background: #FBFBFB;@text-color: #363F4A;@layout-header-background: #223065;