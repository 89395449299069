// defaults: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

/*
 * MODIFY ANT THEME IN .rescriptsrc.js
 */ 


/* 
 * Amplify Theming 
 * 
 * links Amplify UI theme to customized Ant theme 
 *
 */
:root {
  --amplify-font-family: @font-family;
  
  --amplify-primary-color: @primary-color;
  --amplify-primary-tint: @primary-5;
  --amplify-primary-shade: @primary-7;

  --amplify-white: @white;
  --amplify-red: @red-base;
}