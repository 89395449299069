@mock-color: #43b139;

#reportsviewer {
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

  @border-radius: 7px;
  @box-shadow: 0 0 29px rgba(12, 15, 50, 0.09);
  @color1: #465fc3;

  @sidebargrey: #a5aabf;

  font-family: "Inter", sans-serif;
  letter-spacing: -0.035em;
  font-weight: 600;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media only screen and (max-width: 1280px) {
    zoom: 90%;
  }

  .rounded {
    border-radius: @border-radius;
  }

  .drop-shadow {
    box-shadow: @box-shadow;
  }

  .topcontrolsrow {
    padding: 12px 0;
  }

  .topcontrols {
    .ant-affix {
      background-color: @body-background;
      box-shadow: 0 0 29px rgba(12, 15, 50, 0.2);
    }
  }

  .reportheader {
    border-radius: @border-radius;
    box-shadow: @box-shadow;
  }

  .ant-layout-sider {
    overflow: auto;
    height: 100vh !important;
    width: 227px !important;
    max-width: 227px !important;
    min-width: 227px !important;
    position: fixed;
    left: 0;
    box-shadow: @box-shadow;
  }

  .ant-collapse-borderless {
    background-color: transparent;
  }

  .ant-collapse-item {
    border-bottom: 0 !important;
    margin: 16px 0;
  }

  .ant-collapse-header {
    background-color: @component-background;
    border-radius: 7px !important;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    padding-top: 5px !important;
    padding-bottom: 6px !important;

    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    .field {
      .field-desc {
        font-size: 12px;
        line-height: 14px;
        color: @sidebargrey !important;

        .anticon {
          margin-left: 3px;
        }
      }
    }

    >div:first-of-type {
      margin: auto 0; // vertically center collapse arrow '>' 
    }
  }

  .ant-col>.ant-collapse>.ant-collapse-item .ant-collapse-content-box {
    margin-left: 32px;
    padding-left: 0;
    padding-right: 0;
  }

  .issueheader {
    margin-left: 60px;
    margin-right: 60px;
  }

  .category-tag {
    &:extend(#reportsviewer .rounded);
    &:extend(#reportsviewer .drop-shadow);
    background-color: @color1;
    color: #fdfdfd;
  }

  .tags {
    position: absolute;
    top: 11px;
    right: 54px;
    height: 40px;
  }

  .copy-button {
    position: absolute;
    top: 11px;
    right: 20px;
    height: 44px;
  }

  .tags .anticon {
    font-size: 20px;
  }

  .tags .ant-btn {
    max-width: 13vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tags .ant-btn {
    &:extend(#reportsviewer .rounded);
    &:extend(#reportsviewer .drop-shadow);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    height: 37px;
    padding: 6px 20px;
    border: none;
    margin-top: 3px;
  }

  .subissue {
    scroll-margin-top: 150px;
  }

  .subissue .ant-collapse-content {
    background-color: @component-background !important;
  }

  .superissue .ant-collapse-content {
    background-color: transparent;
  }

  .superissue,
  .subissue,
  .superissue .metadata {
    box-shadow: 0 2px 29px rgba(45, 48, 93, 0.09);
  }

  .superissue div.highlight {
    position: absolute;
    left: -2px;
    top: 12px;
    width: 4px;
    height: 41px;
    border-radius: 3px;
    background: #faa263;
  }

  .superissue div.highlight.positive {
    background: #43b139;
  }

  .superissue div.highlight.neutral {
    background: @sidebargrey;
  }

  .superissue div.highlight.negative {
    background: #ff4e4e;
  }

  .subissue div.highlight {
    position: absolute;
    left: -2px;
    top: 12px;
    width: 4px;
    height: 41px;
    border-radius: 3px;
    background: #faa263;
  }

  .subissue div.highlight.positive {
    background: #43b139;
  }

  .subissue div.highlight.neutral {
    background: @sidebargrey;
  }

  .subissue div.highlight.negative {
    background: #ff4e4e;
  }

  .superissue .metadata {
    border-radius: @border-radius;
    background-color: @component-background;
    margin: 10px 0 10px 20px;
  }

  .subissue .metadata {
    border-radius: none;
    box-shadow: none;
    margin: 0;
  }

  .subissue .metadata .ant-statistic .ant-statistic-content-value {
    font-size: 20px;
  }

  .superissue.ant-collapse-item-active {
    box-shadow: none;
  }

  .superissue.ant-collapse-item-active>.ant-collapse-header {
    box-shadow: 0 2px 29px rgba(45, 48, 93, 0.09);
  }

  .superissue .ant-collapse {
    margin-left: 20px;
  }

  .recharts-legend-item-text {
    color: rgba(165, 170, 191, 1) !important;
  }

  .dot {
    width: 5px;
    height: 5px;
    border-radius: 20px;
    background: #a5aabf;
  }

  .ant-card-grid {
    border: none;
    box-shadow: none;
  }

  .hover-only {
    visibility: hidden;
  }

  .evidence:hover {
    cursor: pointer;
    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset,
      0 1px 0 0 #f0f0f0 inset;

    .hover-only {
      visibility: visible;
    }
  }

  .evidence {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    flex-basis: 33%;
    flex-shrink: 0;
    min-width: 0;
    padding: 10px 20px;
    position: relative;

    font-weight: normal;

    .evidence-header-review {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
    }

    .evidence-header-metadata {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
    }

    .evidence-header-crm {
      font-size: 14px;
      position: absolute;
      top: 10px;
      right: 20px;
    }

    // review style evidence text
    .evidence-text {
      font-size: 17px;
    }

    // chat bubble style evidence text
    .chat-bubble {
      font-size: 17px;
      background-color: #465FC3;
      color: #fdfdfd;
      border-radius: 15px 15px 15px 3px;
      padding: 10px 20px;
      margin: 7px 0;
      width: 90%;
    }
  }

  .mock {
    outline: 2px solid @mock-color !important;
  }
}

.mock-checkbox {
  padding-left: 2px;

  .ant-checkbox-inner {
    outline: 2px solid @mock-color !important;
  }
}

.review {
  width: 85%;
  white-space: pre-wrap;
}

.message {
  width: 85%;
  white-space: pre-wrap;
}

.message.customer {
  background-color: #465FC3;
  color: #fdfdfd;
  border-radius: 15px 15px 15px 3px;
  padding: 10px 20px;
  margin: 7px 0;
}

.message.agent {
  background-color: #dcdcdc;
  color: #333333;
  border-radius: 15px 15px 3px 15px;
  padding: 10px 20px;
  margin: 7px 0 7px 15%;
}

.message.system {
  color: #888888;
  text-align: center;
  font-size: smaller;
  margin: 7px 0 7px 7%;
}

.modal .message.customer::selection {
  color: #333333;
  background: #dcdcdc;
}

.modal .message.agent::selection {
  color: #fdfdfd;
  background: #465fc3;
}

.removeEvidenceModal {
  position: absolute;
  right: 0;
  bottom: 0;
}
@primary-color: #465FC3;@body-background: #F4F4F7;@component-background: #FBFBFB;@text-color: #363F4A;@layout-header-background: #223065;